import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SmartLink } from '../../SmartLink';
import { Image } from '../../Image';
import { getMainImages } from '../../../domain/utils/contentUtils';
import { AnimatedButton } from '../../responsive/atoms/AnimatedButton';
import { t } from '../../../domain/services/configService';

const elementsAlignConfig = {
  justify: 'space-between',
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

export const CardStackItem = ({ data, isAboveTheFold }) => {
  const styles = {
    cardStackItem: css`
      position: relative;
      width: 100%;
      aspect-ratio: 3 / 4;
      a {
        color: inherit;
      }
    `,
    imageContainer: css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      aspect-ratio: 3 / 4;
      opacity: ${(data.mediaTint || 100) / 100};
      &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background: ${data.gradient
          ? `linear-gradient(
          180deg,
          ${data.theming === 'light' ? '#fff' : '#000'}9,
          ${data.theming === 'light' ? '#fff' : '#000'}0 ${data.gradient}%)
        `
          : null};
      }
    `,
    contentContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: ${elementsAlignConfig[data.elementsAlign]};
      padding: 24px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      text-align: center;
      aspect-ratio: 3 / 4;
    `,
    sponsoredContent: css`
      display: inline-block;
      background-color: white;
      color: black;
      ${theme.font.family('boldWeb')};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 3px;
      line-height: 1;
      padding: 8px 8px 6px;
      margin-bottom: 20px;
      text-transform: uppercase;
      white-space: nowrap;
    `,
    ptt: css`
      ${theme.font.family('boldWeb')};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 3px;
      margin-bottom: 16px;
      padding-bottom: 16px;
      position: relative;
      text-transform: uppercase;
      :hover {
        text-decoration: underline;
      }
    `,
    yellowLine: css`
      :after {
        background-color: #fc0;
        bottom: 0;
        content: '';
        height: 3px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.3s;
        width: 24px;
      }
    `,
    cardTitle: css`
      ${theme.font.family('medium')};
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 1.3;
      margin: 0 0 16px;
      position: relative;
      text-align: center;
      margin-bottom: 24px;
      padding-bottom: 16px;
      position: relative;
    `,
    cardSubtitle: css`
      ${theme.font.family('regular')};
      font-size: 14px;
      margin-bottom: 12px;
      position: relative;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    `
  };

  const title =
    data.titleOverride ||
    data.card?.entity?.promoTitle ||
    data.card?.entity?.title;
  // Subtitle is optional, and it's not filled in by default.
  const { subtitle } = data;
  const image = data.card?.entity
    ? getMainImages(data.card.entity)[0]?.entity
    : null;
  const focalPoint = data.focalPoint || image?.focalPoint;
  if (image && focalPoint) {
    image.focalPoint = focalPoint;
  }
  const link =
    data.link?.url?.path ||
    data.card?.entity?.link?.url?.path ||
    data.card?.entity?.url?.path;
  const isSponsoredContent =
    data.card?.entity?.sponsoredTextCard &&
    data.card?.entity?.branded &&
    data.showExternalSponsorLabel;
  const pttTitle = data.card?.entity?.primaryTaxonomy?.entity?.hubPage?.title;
  const target = data.openLinkInNewTab ? '_blank' : null;

  return (
    <div css={styles.cardStackItem}>
      <div css={styles.imageContainer}>
        {image ? (
          <Image
            entity={image}
            maxWidths={{
              mobile: 330,
              tablet: 330,
              small_desktop: 330,
              large_desktop: 330
            }}
            aspect="3x4"
            loading={isAboveTheFold ? 'eager' : 'lazy'}
          />
        ) : null}
      </div>
      <div css={styles.contentContainer}>
        <article>
          {isSponsoredContent && (
            <div css={[styles.sponsoredContent]}>
              {data.card.entity.sponsoredTextCard}
            </div>
          )}

          {!data.hidePtt && !isSponsoredContent && !!pttTitle && (
            <div css={[styles.ptt, styles.yellowLine]}>
              <SmartLink to={link} target={target} title={title}>
                {pttTitle}
              </SmartLink>
            </div>
          )}
          {!!title && (
            <h2
              css={[
                styles.cardTitle,
                isSponsoredContent ? styles.yellowLine : null
              ]}
            >
              <SmartLink to={link} target={target} title={title}>
                {title}
              </SmartLink>
            </h2>
          )}
          {!!subtitle && <div css={styles.cardSubtitle}>{subtitle}</div>}
        </article>
        {!!data.link?.url?.path && (
          <AnimatedButton
            to={link}
            target={target}
            bgColor="yellow"
            hoverBgColor="black"
            size="large"
          >
            {t('Read More')}
          </AnimatedButton>
        )}
      </div>
    </div>
  );
};

CardStackItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool.isRequired
};
