import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SmartLink } from '../../SmartLink';

const getTextColor = bgColor =>
  ['yellow', 'white', 'gray'].includes(bgColor) ? 'black' : 'white';
const getHtmlValidColor = color =>
  color.replace('yellow', '#fc0').replace('gray', '#f7f7f7');

export const AnimatedButton = ({
  to,
  target,
  bgColor: rawBgColor,
  hoverBgColor: rawHoverBgColor,
  outlined,
  size,
  icon,
  children
}) => {
  const textColor = getTextColor(rawBgColor);
  const hoverTextColor = getTextColor(rawHoverBgColor);
  const bgColor = getHtmlValidColor(rawBgColor);
  const hoverBgColor = getHtmlValidColor(rawHoverBgColor);

  const padding = { small: '10px 20px', large: '16.5px 20px', xlarge: '18px' };
  const fontSize = { small: '0.75rem', large: '0.75rem', xlarge: '1rem' };
  const lineHeight = { small: '1rem', large: '0.75rem', xlarge: '1.5rem' };

  const styles = {
    main: css`
      a {
        position: relative;
        z-index: 1;
        display: inline-flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        ${theme.font.family('boldWeb')};
        letter-spacing: 3px;
        text-align: center;
        text-transform: uppercase;
        transition: color 0.3s;

        background-color: ${bgColor};
        color: ${textColor};

        ${outlined && `border: 2px solid ${textColor};`}
        :hover {
          color: ${hoverTextColor};
          :before {
            width: 100%;
          }
        }

        padding: ${padding[size]};
        font-size: ${fontSize[size]};
        line-height: ${lineHeight[size]};

        :before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          width: 0;
          height: 100%;
          background-color: ${hoverBgColor};
          transition: width 0.3s ease-in-out;
        }
      }
    `,
    icon: css`
      svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
    `
  };

  return (
    <div css={styles.main}>
      <SmartLink to={to} target={target}>
        {icon && <div css={styles.icon}>{icon}</div>}
        {children}
      </SmartLink>
    </div>
  );
};

AnimatedButton.defaultProps = {
  target: undefined,
  bgColor: 'yellow',
  hoverBgColor: 'black',
  outlined: false,
  size: 'large',
  icon: undefined
};

AnimatedButton.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  bgColor: PropTypes.oneOf(['yellow', 'black', 'white', 'gray']),
  hoverBgColor: PropTypes.oneOf(['yellow', 'black', 'white']),
  outlined: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'xlarge']),
  icon: PropTypes.node,
  children: PropTypes.node.isRequired
};
