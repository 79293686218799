import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CardStackItem } from './CardStackItem';
import { CoverFlowWrapper } from './CoverFlowWrapper';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { getColors } from '../../../domain/utils/colorUtils';
import { facePaint } from '../../../domain/theme/utils/facePaint';

const CardStackInner = ({ data, isAboveTheFold }) => {
  const { cards, brandedLogo, brandedClickThrough, title, subtitle } = data;

  const colors = getColors(data.backgroundColor);
  const backgroundColor = colors.background;
  const fontColor = colors.color;
  const hasHeader = !!(brandedLogo || title || subtitle);

  const styles = {
    mainContainer: css`
      background-color: ${backgroundColor};
      color: ${fontColor};
      width: 100%;
      ${facePaint({
        'padding-top': hasHeader ? null : ['20px', '20px', '60px', '60px']
      })};
    `
  };

  const carouselItems = cards?.map(card => {
    return {
      key: card.entity.key || card.entity.id,
      orientation: 'portrait',
      element: (
        <CardStackItem
          data={{
            ...card.entity,
            elementsAlign: data.elementsAlign || 'justify',
            theming:
              data.theming || (backgroundColor === 'black' ? 'light' : 'dark')
          }}
          isAboveTheFold={isAboveTheFold}
        />
      )
    };
  });

  const branded = { logo: brandedLogo, link: brandedClickThrough?.url?.path };

  return (
    <section css={styles.mainContainer}>
      <CoverFlowWrapper
        items={carouselItems}
        backgroundColor={backgroundColor}
        theming={
          data.theming || (backgroundColor === 'black' ? 'light' : 'dark')
        }
        branded={branded.logo ? branded : null}
        heading={title}
        description={subtitle}
      />
    </section>
  );
};

CardStackInner.defaultProps = {
  isAboveTheFold: false
};

CardStackInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const CardStack = ParagraphWrapper(CardStackInner);
